<template>
    <div class="about content-wide" style="padding-top: 128px">
        <div class="content-img-container">
            <!-- <img src="@/assets/img/7.jpeg" :alt="$t('about.title')" /> -->
        </div>

        <h1 class="big-title">{{ $t('about.title') }}</h1>
        <div class="about-content">
            {{ $t('about.bigText1') }}<br />
            <br />
            {{ $t('about.bigText2') }}
        </div>
    </div>
</template>

<script src="./About.ts"></script>
<style lang="scss" scoped>
@import './About.scss';
</style>
